<template>
  <div>
    <v-card>
      <v-card-title class="text-h6">
        {{ $t("labels.rack") }}
        <v-spacer></v-spacer>
        <v-btn small color="red darken-1" text @click="close">
          {{ $t("labels.close") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <custom-table
          ref="customTable"
          :table-height="`calc(100vh - 280px)`"
          :columns="columns"
          :footer-actions="footerActions"
          :sums="sums"
          :is-page-show="true"
          :default-filters="defaultFilters"
          api-url="/warehouse/v1/rack-list"
        ></custom-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Rack",
  components: {},
  props: {
    idWarehouse: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    defaultFilters: {},
  }),
  computed: {},
  watch: {},
  created() {
    this.defaultFilters = {
      id_warehouse: this.idWarehouse,
    };
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.zone"),
        placeholder: this.$t("labels.zone"),
        name: "zone",
        hasSort: false,
        sortName: "zone",
        key: "zone",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.position"),
        placeholder: this.$t("labels.position"),
        name: "position",
        hasSort: false,
        sortName: "position",
        key: "position",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.bff"),
        placeholder: this.$t("labels.bff"),
        name: "bff",
        hasSort: false,
        sortName: "bff",
        key: "bff",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.max_level"),
        placeholder: this.$t("labels.max_level"),
        name: "max_level",
        hasSort: false,
        sortName: "max_level",
        key: "max_level",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.max_cell_each_level"),
        placeholder: this.$t("labels.max_cell_each_level"),
        name: "max_cell_each_level",
        hasSort: false,
        sortName: "max_cell_each_level",
        key: "max_cell_each_level",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.start_cell"),
        placeholder: this.$t("labels.start_cell"),
        name: "start_cell",
        hasSort: false,
        sortName: "start_cell",
        key: "start_cell",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.cell_type"),
        placeholder: this.$t("labels.cell_type"),
        name: "cell_type_name",
        hasSort: false,
        sortName: "cell_type_name",
        key: "cell_type_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.lock_cell"),
        placeholder: this.$t("labels.lock_cell"),
        name: "lock_cell",
        hasSort: false,
        sortName: "lock_cell",
        key: "lock_cell",
        required: true,
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  mounted() {},
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
